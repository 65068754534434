// businessHoursData.js
const businessHoursData = [
  { day: "Tuesday", hours: "10:00 AM - 8:00 PM" },
  { day: "Wednesday", hours: "10:00 AM - 8:00 PM" },
  { day: "Thursday", hours: "10:00 AM - 8:00 PM" },
  { day: "Friday", hours: "10:00 AM - 8:00 PM" },
  { day: "Saturday", hours: "8:30 AM - 9:00 PM" },
  { day: "Sunday", hours: "8:00 AM - 8:00 PM" },
  { day: "Monday", hours: "Closed" },
];

export default businessHoursData;
