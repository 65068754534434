import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SearchManagement from "../search/SearchManagement";
import SearchResults from "../search/SearchResults";
import ErrorMessage from "../common/ErrorMessage";
import ProfileHeader from "../common/ProfileHeader";

const ManagementPerson = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const resultsRef = useRef(null); // Create a ref for the search results section
  const navigate = useNavigate();

  const handleCustomMessageClick = () => {
    navigate("/custom-message", { state: { phoneNumber: "" } });
  };

  const handleSearch = async () => {
    setHasSearched(true);
    const trimmedQuery = searchQuery.trim();

    if (!trimmedQuery) {
      setErrorMessage("Please enter a search term.");
      setSearchResults([]);
      return;
    }

    setErrorMessage(""); // Clear previous errors

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}search?query=${encodeURIComponent(
          trimmedQuery
        )}`
      );

      if (response.data.length === 0) {
        setSearchResults([]);
        setErrorMessage("No results found.");
      } else {
        setSearchResults(response.data);
        setErrorMessage(""); // Clear error if results are found
        scrollToResults(); // Scroll to the results section
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      setErrorMessage("No Record Found.");
      setSearchResults([]); // Clear previous search results on error
    }
  };

  const handleCancel = () => {
    setSearchQuery("");
    setSearchResults([]);
    setErrorMessage("");
    setHasSearched(false);
  };

  const scrollToResults = () => {
    if (resultsRef.current) {
      resultsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (hasSearched && searchResults.length > 0) {
      scrollToResults();
    }
  }, [searchResults, hasSearched]);

  return (
    <>
      <ProfileHeader />
      <div className="max-w-md mx-auto mt-4 mb-16 bg-slate-800 backdrop-blur-lg rounded-lg shadow-lg p-6 border border-white/30">
        <div className="px-6 pb-6">
          <SearchManagement
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleSearch={handleSearch}
            handleCancel={handleCancel} // Pass the handleCancel function
          />
          {/* Display error message only if a search has been attempted and there is an error message */}
          {hasSearched && errorMessage && (
            <ErrorMessage message={errorMessage} />
          )}
          <div ref={resultsRef}>
            <SearchResults
              searchResults={searchResults}
              hasSearched={hasSearched}
            />
          </div>
          <div className="mt-6 text-center">
            <button
              className="bg-green-600 font-acme tracking-widest text-gray-100 py-2 px-3 rounded-lg text-center duration-500 ease-in-out transform hover:scale-110 hover:bg-white hover:text-green-600"
              onClick={handleCustomMessageClick}
            >
              DM ME ON WHATSAPP
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagementPerson;
