/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { QRCodeCanvas } from "qrcode.react"; // Import a QR code generator package

const QRCodePage = () => {
  // Use production URL in the QR code value
  const productionURL = "https://manimozhi.vcard-aam.com/";

  return (
    <div className="max-w-md mx-auto text-center text-white py-10 bg-gray-700 rounded-lg shadow-lg p-6">
      <div className="flex flex-col items-center justify-center h-screen bg-gray-700">
        <h1 className="text-2xl font-semibold text-white mb-6">
          Scan this QR code View my VCard
        </h1>
        <QRCodeCanvas value={productionURL} size={256} level={"H"} />
        <p className="text-white mt-4">Scan this QR code to visit the VCard.</p>
      </div>
    </div>
  );
};

export default QRCodePage;
