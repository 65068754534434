import React, { Suspense } from "react";
import SocialMediaIcons from "../common/SocialMediaIcons";
import ProfileImage from "../../profileImage/Manimozhi.jpg";
import TopImage from "../../profileImage/Top_image.jpg";
import ContactDetails from "../common/ContactDetails"; // Import the new component

const ActionButtonsPanel = React.lazy(() =>
  import("../common/ActionButtonsPanel")
);
const Service = React.lazy(() => import("./Service"));
const AddressSection = React.lazy(() => import("../common/AddressSection"));
const Gallery = React.lazy(() => import("../common/Gallery"));
const Status = React.lazy(() => import("../common/Status"));

const VCard = () => {
  return (
    <div className="max-w-md mx-auto bg-slate-800 text-white rounded-lg shadow-lg overflow-hidden pb-20">
      {/* Top image */}
      <div className="relative">
        <img
          src={TopImage}
          alt="top img"
          className="w-full h-56 object-cover transition-transform duration-500 ease-in-out transform hover:scale-110"
        />
      </div>

      {/* Profile Section */}
      <div className="relative flex items-center p-6 -mt-24">
        <img
          src={ProfileImage}
          alt="Manimozhi Shiva Shankar"
          className="w-40 h-36 rounded-3xl border-4 border-white hover:border-slate-800  transform duration-300 ease-in-out hover:scale-95"
        />
        <h2 className="ml-6 mt-20 text-2xl tracking-wider font-semibold text-md font-oswald duration-500 ease-in-out transform hover:scale-110">
          Manimozhi Shiva Shankar
        </h2>
      </div>

      {/* Social Media Icons */}
      <div className="text-left pb-6">
        <div className="flex justify-center">
          <SocialMediaIcons />
        </div>
      </div>

      {/* About me */}
      <div className="ml-6 mr-4">
        <p className="font-acme text-lg tracking-wider text-center">
          I'm Manimozhi Shiva Shankar, an entrepreneur blending wellness and
          technology. As a{" "}
          <span className="text-yellow-500 text-xl">
            Forever Business Owner
          </span>
          , I promote Aloe Vera products for holistic health. I lead{" "}
          <span className="text-yellow-500 text-xl">V4U Africa Pvt Ltd</span>,
          offering various servies and IT solutions, and own{" "}
          <span className="text-yellow-500 text-xl">
            Flavours of India Restaurant
          </span>
          , bringing authentic Indian cuisine to South Africa. Committed to
          excellence, I aim to make a lasting impact in everything I do.
        </p>
      </div>

      <hr className="border-t-2 border-gray-400 rounded-full mt-7 m-4" />

      {/* Contact Information */}
      <div className="text-left p-6 pt-2 space-y-4">
        <h1 className="text-center text-3xl font-dancing-script">Contacts</h1>
        <span className="block mx-auto w-12 h-1 bg-yellow-500 mb-4"></span>

        {/* ContactDetails Component */}
        <ContactDetails />
      </div>

      <hr className="border-t-2 border-gray-400 rounded-full mt-7 m-4" />

      <Suspense fallback={<div>Loading...</div>}>
        <Status />

        <hr className="border-t-2 border-gray-400 rounded-full mt-7 m-4" />

        {/* Buttons with Icons */}
        <ActionButtonsPanel />

        {/* Products */}
        <Service />

        <hr className="border-t-2 border-gray-400 rounded-full mt-7 m-4" />

        {/* Gallery */}
        <Gallery />

        <hr className="border-t-2 border-gray-400 rounded-full mt-7 m-4" />

        {/* Address Section */}
        <AddressSection />
      </Suspense>
    </div>
  );
};

export default React.memo(VCard);
