import React from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

import ProfileImage from "../../profileImage/Manimozhi.jpg";
import TopImage from "../../profileImage/Top_image.jpg";
import SocialMediaIcons from "./SocialMediaIcons";

const ProfileHeader = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (window.history.length > 1) {
      navigate(-1); // This will take the user back to the previous page if there is history
    } else {
      window.location.href = "/"; // Force a reload to the home page
    }
  };

  return (
    <div className="max-w-md mx-auto bg-slate-800 text-white rounded-lg shadow-lg overflow-hidden pb-4">
      {/* Top image */}
      <div className="relative">
        <img
          src={TopImage}
          alt="top img"
          className="w-full h-48 object-cover transition-transform duration-500 ease-in-out transform hover:scale-110"
        />
        <button
          onClick={handleBackClick}
          className="absolute top-3 left-3 bg-white/50 text-black py-1 px-1 rounded-full hover:bg-white/30 hover:text-white flex items-center"
        >
          <FaArrowLeft className="m-1" /> {/* Back icon */}
        </button>
      </div>

      {/* Profile Section */}
      <div className="relative flex flex-col items-center sm:flex-row p-6 -mt-16 sm:-mt-20">
        <img
          src={ProfileImage}
          alt="Mani Shiva Shankar"
          className="w-32 h-32 sm:w-40 sm:h-40 rounded-full border-4 border-white"
        />
        <h2 className="text-white mt-4 sm:mt-0 sm:ml-6 text-center sm:text-left text-2xl sm:text-3xl tracking-wider duration-500 ease-in-out transform hover:scale-110 font-semibold font-oswald">
          Manimozhi Shiva Shankar
        </h2>
      </div>

      <SocialMediaIcons />
    </div>
  );
};

export default ProfileHeader;
