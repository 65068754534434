/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import ProfileImage from "../../../profileImage/Manimozhi.jpg";
import TopImage from "../../../profileImage/Restaurant_Cover_image.jpg";
import SocialMediaIcons from "../SocialMediaIcons";

const RestaurantProfileHeader = () => {
  return (
    <div className="max-w-md mx-auto bg-slate-800 text-white rounded-lg shadow-lg overflow-hidden pb-4">
      {/* Top Image */}
      <div className="relative">
        <img
          src={TopImage}
          alt="Top cover image"
          className="w-full h-48 object-cover transition-transform duration-500 ease-in-out transform hover:scale-110"
          loading="lazy"
        />
      </div>

      {/* Profile Section */}
      <div className="relative flex items-center p-6 -mt-24">
        <img
          src={ProfileImage}
          alt="Manimozhi Shiva Shankar Profile"
          className="w-40 h-36 rounded-3xl border-4 border-white hover:border-slate-800 transform duration-300 ease-in-out hover:scale-95"
          loading="lazy"
        />
        <h2 className="ml-6 mt-20 text-2xl tracking-wider font-semibold font-oswald transition-transform duration-500 ease-in-out hover:scale-110">
          Manimozhi Shiva Shankar
        </h2>
      </div>

      {/* Social Media Icons */}
      <SocialMediaIcons />
    </div>
  );
};

export default RestaurantProfileHeader;
