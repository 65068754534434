import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import ContactDetails from "../whatWeDo/WhatWeDoContactDetails";
import HowCanWeHelp from "./HowCanWeHelp";
import WhatWeDoProfileHeader from "./WhatWeDoProfileHeader";

const WhatWeDo = () => {
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Memoized function to ensure valid URLs
  const validUrl = useCallback(
    (url) =>
      url.startsWith("http://") || url.startsWith("https://")
        ? url
        : `http://${url}`,
    []
  );

  // Fetch What We Do data from API
  const fetchWhatWeDoData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}what-we-do`
      );
      setSections(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching What We Do data:", error);
      setError("Failed to load data.");
      setLoading(false);
    }
  }, []);

  // Fetch data on component mount
  useEffect(() => {
    fetchWhatWeDoData();
  }, [fetchWhatWeDoData]);

  // Show loading state
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-white">Loading...</p>
      </div>
    );
  }

  // Show error state
  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <>
      <WhatWeDoProfileHeader />
      <div className="what-we-do-container max-w-md mx-auto mb-7 bg-slate-800 rounded-lg shadow-lg p-6 backdrop-blur-lg border border-white/10">
        {/* Contact Section */}
        <div className="text-5xl text-center text-white hover:text-yellow-500 mb-7 tracking-wider font-semibold font-acme duration-500 ease-in-out transform hover:scale-110">
          <h1>V4U AFRICA</h1> <p> PTY. LTD.</p>
        </div>
        <hr className="border-t-2 border-gray-400 rounded-full mt-5 mb-10" />

        <div className="text-left p-4 space-y-4">
          <h1 className="text-center text-white text-3xl font-dancing-script">
            Contacts
          </h1>
          <span className="block mx-auto w-12 h-1 bg-yellow-500 mb-4"></span>
          <ContactDetails />
        </div>

        <hr className="border-t-2 border-gray-400 rounded-full mt-7 mb-10" />

        {/* Render sections */}
        {sections.map(({ id, image, heading, readmore_link }) => (
          <div
            key={id}
            className="max-w-md mt-3 mx-auto bg-white/40 text-white rounded-lg shadow-lg overflow-hidden"
          >
            <div className="mb-10">
              <div className="flex justify-center">
                <img
                  src={image}
                  alt={heading}
                  className="w-full h-48 object-cover rounded-lg mb-4 transition-transform duration-500 ease-in-out transform hover:scale-110"
                />
              </div>
              <h2 className="text-2xl font-bold text-center mb-4 px-2">
                {heading}
              </h2>
              <div className="flex justify-center">
                <a
                  href={validUrl(readmore_link)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-center bg-blue-700 text-white py-2 px-8 transition-transform duration-500 ease-in-out transform hover:scale-110 rounded-md hover:text-blue-700 hover:bg-white focus:outline-none focus:ring-4 focus:ring-blue-500"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      <HowCanWeHelp />
    </>
  );
};

export default WhatWeDo;
