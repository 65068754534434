import React, { useState } from "react";
import ContactInfo from "../ContactInfo";

const ContactDetails = () => {
  const [showFullEmail, setShowFullEmail] = useState(false); // Toggle email visibility
  const obfuscatedEmail = "sales[at]v4uafrica[dot]com";

  return (
    <div>
      <div className="grid grid-cols-2 gap-6 mt-6 space-x-14">
        {/* Email Section */}
        <ContactInfo
          type="email"
          label="E-mail Address"
          value={showFullEmail ? "sales@v4uafrica.com" : obfuscatedEmail}
          toggleEmail={() => setShowFullEmail(!showFullEmail)}
        />

        {/* Phone Section */}
        <ContactInfo
          type="phone"
          label="Phone Number"
          value="(+27) 620556906"
          href="tel:+27620556906"
        />
      </div>

      <div className="grid grid-cols-2 gap-6 mt-6 space-x-14">
        {/* Website Section */}
        <ContactInfo
          type="website"
          label="V4U Africa Pvt. Ltd."
          value="V4U Africa"
          href="https://v4uafrica.com/"
          target="_blank"
          rel="noopener noreferrer" // Prevent tabnabbing
        />

        {/* Restaurant Section */}
        <ContactInfo
          type="restaurant"
          label="Our Restaurant"
          value="Flavours of India"
          href="https://www.ubereats.com/za/store/flavours-of-india-rsa/50yYiDBJQl2Z9wPDQEEhug"
          target="_blank"
          rel="noopener noreferrer" // Prevent tabnabbing
        />
      </div>
    </div>
  );
};

export default ContactDetails;
