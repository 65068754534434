import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa"; // Import the Font Awesome icon from react-icons
import axios from "axios";

import ProfileImage from "../../profileImage/Manimozhi.jpg";
import TopImage from "../../profileImage/Top_image.jpg";

const CommunityDirectory = () => {
  const [totalMembers, setTotalMembers] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTotalMembers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}total-members`
        );
        setTotalMembers(response.data.total);
      } catch (error) {
        console.error("Error fetching total members:", error);
      }
    };

    fetchTotalMembers();
  }, []);

  const handleBackClick = () => {
    navigate(-1); // This will take the user back to the previous page
  };

  const handleSearchByPersonName = () => {
    navigate("/search-person");
  };

  const handleSearchByCompanyName = () => {
    navigate("/search-company");
  };

  return (
    <>
      <div className="min-h-screen flex flex-col justify-between max-w-md mx-auto bg-slate-800 rounded-lg shadow-lg overflow-hidden">
        {/* Top section */}
        <div className="flex-1 pb-10">
          {/* Added pb-16 for padding-bottom */}
          {/* Top image */}
          <div className="relative">
            <img
              src={TopImage}
              alt="top img"
              className="w-full h-48 object-cover transition-transform duration-500 ease-in-out transform hover:scale-110"
            />
            <button
              onClick={handleBackClick}
              className="absolute top-3 left-3 bg-white/50 text-black py-1 px-1 rounded-full hover:bg-white/30 hover:text-white flex items-center"
            >
              <FaArrowLeft className="m-1" /> {/* Back icon */}
            </button>
          </div>
          {/* Profile image and details */}
          <div className="text-center p-6 bg-slate-800">
            <img
              src={ProfileImage}
              alt="Manimozhi Shiva Shankar"
              className="w-44 h-44 rounded-full m-auto pt-50 border-4 border-white"
            />
            <h2 className="text-white ml-6 mt-6 text-3xl tracking-wider duration-500 ease-in-out transform hover:scale-110 font-semibold text-md font-oswald">
              Manimozhi Shiva Shankar
            </h2>
            <p className="text-yellow-500 text-3xl mt-4 font-dancing-script">
              Forever
            </p>

            <div className="mt-6">
              <h3 className="text-lg font-semibold text-white">
                Community Directory
              </h3>
              <p className="text-gray-300 font-asap-condensed text-lg mt-2">
                Total Members:{" "}
                {totalMembers !== null ? totalMembers : "Loading..."}
              </p>

              <div className="mt-4 space-y-3">
                <button
                  onClick={handleSearchByPersonName}
                  className="w-full md:w-96 mx-auto py-2 mb-2 font-bebas-neue text-xl hover:text-gray-800 hover:text-2xl tracking-widest duration-500 ease-in-out transform hover:scale-105 bg-white/30 text-white font-bold rounded-md hover:bg-white/40 backdrop-blur-lg flex items-center justify-center shadow-lg border border-white/20"
                >
                  <i className="fas fa-user mr-2"></i>
                  Search Forever Members
                </button>
                <button
                  className="w-full md:w-96 mx-auto py-2 mb-2 font-bebas-neue text-xl hover:text-gray-800 hover:text-2xl tracking-widest duration-500 ease-in-out transform hover:scale-105 bg-white/30 text-white font-bold rounded-md hover:bg-white/40 backdrop-blur-lg flex items-center justify-center shadow-lg border border-white/20"
                  onClick={handleSearchByCompanyName}
                >
                  <i className="fas fa-briefcase mr-2"></i>
                  Search by Company Name
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommunityDirectory;
